$body-bg: #f9f9f9;
$body-color: #333;
$card-cap-bg: #fff;
$blue: #5563aa;
$orange: #ff6212;
$text-muted: #d5dbe0;

.btn {
  background-color: transparent;
}

.bg-smartmansys {
  background-image: linear-gradient(to right, #2b3155 0%, #5563aa 100%);
}

.table-sticky thead th {
  background-color: white;
  position: sticky;
  top: 0px;
  z-index: 1010;
}

@import '~bootstrap/scss/bootstrap.scss';

.custom-file-input {
  cursor: pointer;
}

.text-truncate::before {
  content: '';
  display: block;
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.scroll-content {
  height: calc(100vh - 182px);
  overflow-y: scroll;
}

[tooltip] {
  position: relative;
}

[tooltip]::before {
  content: '';
  border-width: 4px 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
  margin-top: 8px;
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 100%;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition-delay: 0.2s;
  transform: translateX(-50%) translatey(20px) rotate(-180deg);
  z-index: 100;
}

[tooltip]:after {
  color: var(--white);
  content: attr(tooltip);
  background-color: var(--gray-dark);
  border-radius: 4px;
  font-size: 0.75rem;
  left: 50%;
  line-height: 1.25rem;
  opacity: 0;
  padding: 4px 8px;
  pointer-events: none;
  position: absolute;
  text-align: left;
  top: 100%;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition-delay: 0.2s;
  transform: translateX(calc(-50% - 8px)) translateY(20px);
  visibility: hidden;
  white-space: nowrap;
  z-index: 10;
}

[tooltip]:hover:before {
  opacity: 1;
  transform: translateX(-50%) translatey(-100%) rotate(-180deg);
  visibility: visible;
}
[tooltip]:hover:after {
  opacity: 1;
  transform: translateX(calc(-50% - 8px)) translateY(8px);
  visibility: visible;
}
[tooltip]:focus {
  outline: 0;
}

[tooltip][data-placement='right']:before {
  border-color: transparent rgba(0, 0, 0, 0.7) transparent transparent;
  transform: translateX(32px) translateY(-19px);
}

[tooltip][data-placement='right']:after {
  transform: translateX(44px) translateY(-21px);
}

[tooltip][data-placement='right']:hover:before {
  transform: translateX(12px) translateY(-19px);
}

[tooltip][data-placement='right']:hover:after {
  transform: translateX(24px) translateY(calc(-50% - 8px));
}

@supports (padding: m#{a}x(0px)) {
  #root > .container-fluid {
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: m#{a}x(env(safe-area-inset-left), 15px);
    padding-right: m#{a}x(env(safe-area-inset-right), 15px);
  }
}
