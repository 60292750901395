.loading {
  height: 3rem;
  left: calc(50% - 5rem);
  position: absolute;
  text-align: center;
  top: calc(50% - 1.5rem);
  width: 10rem;

  .loading__progress {
    background-color: #ced4da;
    height: 0.5rem;
    margin-top: 1rem;
  }

  .loading__progress__bar {
    background-color: var(--primary);
    height: 0.5rem;
    width: 2rem;
    animation: nightrider 1.5s infinite;
  }
}

#Rectangle-Dark-blue {
  animation: bounce 1.5s infinite;
  animation-delay: 0s;
}
#Rectangle-Blue {
  animation: bounce 1.5s infinite;
  animation-delay: 0.1s;
}
#Rectangle-Light-blue {
  animation: bounce 1.5s infinite;
  animation-delay: 0.2s;
}
#Rectangle-Gray {
  animation: bounce 1.5s infinite;
  animation-delay: 0.3s;
}

@keyframes nightrider {
  0% {
    width: 2rem;
    transform: translate(0, 0);
  }
  25% {
    width: 2.5rem;
  }
  50% {
    width: 2rem;
    transform: translate(8rem, 0);
  }
  75% {
    width: 2.5rem;
  }
  100% {
    width: 2rem;
    transform: translate(0, 0);
  }
}

@keyframes bounce {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, 2rem);
  }
  100% {
    transform: translate(0, 0);
  }
}
