.main-container {
  height: calc(100vh - 62px);
  overflow: hidden;
}

.main-content {
  height: calc(100vh - 62px);
  overflow-y: scroll;
}

.sidebar__navbar {
  background-color: #2b3155;
  max-width: 4rem;
  min-height: calc(100vh - 62px);

  span {
    display: none;
  }

  .sidebar__navbar__menu {
    list-style: none;
    padding: 0;

    .nav-item {
      overflow: hidden;
      white-space: nowrap;

      a {
        color: rgb(232, 232, 232);
        padding-left: 0.5rem;
        padding-right: 0;

        &:hover {
          color: var(--white);
        }
      }
    }
  }

  &:hover {
    max-width: 14rem;

    span {
      display: inline;
    }
  }
}
